<template>
  <div>
    <v-form
      ref="formEstimation"
      v-model="valid"
    >
      <v-alert
        v-if="!!error.search"
        border="left"
        close-text="Fermer"
        type="warning"
        dark
        width="100%"
      >
        {{ error.search }}
      </v-alert>
      <v-card
        :style="`background-color: ${selectedContent.color}`"
        dark
        class="mb-6"
        width="100%"
      >
        <v-card-title
          class="display-1"
          style="word-break: break-word"
          :class="{'headline': $vuetify.breakpoint.xs}"
        >
          {{ selectedContent.searchTitle }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :value="estimate.geoAddress"
            :items="addressList"
            :loading="isLoading.search"
            :search-input.sync="search"
            color="white"
            :rules="mandatoryObj"
            item-text="properties.label"
            placeholder="L'adresse de votre bien"
            :prepend-icon="icons.mdiMapMarker"
            return-object
            hide-no-data
            no-filter
            auto-select-first
            @change="onEvent('geoAddress', $event)"
          />
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="headline">
          Décrivez votre bien
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-subheader class="title">
                Type
              </v-subheader>
              <v-radio-group
                :value="estimate.type"
                :rules="mandatory"
                @change="onEvent('type', $event)"
              >
                <v-radio
                  v-for="(option, id) in options.type"
                  :key="id"
                  :value="option.value"
                  :label="option.label"
                />
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-subheader class="title">
                État
              </v-subheader>
              <v-radio-group
                :value="estimate.condition"
                :rules="mandatory"
                @change="onEvent('condition', $event)"
              >
                <v-radio
                  v-for="(option, id) in options.condition"
                  :key="id"
                  :value="option.value"
                  :label="option.label"
                />
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-subheader class="title">
                Année de construction
              </v-subheader>
              <v-radio-group
                :value="estimate.seniority"
                :rules="mandatory"
                @change="onEvent('seniority', $event)"
              >
                <v-radio
                  v-for="(option, id) in options.seniority"
                  :key="id"
                  :value="option.value"
                  :label="option.label"
                />
              </v-radio-group>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
              <v-subheader class="title mb-8">
                Superficie du bien
              </v-subheader>
              <v-text-field
                :value="estimate.surface"
                single-line
                type="number"
                suffix="㎡"
                :rules="mandatory"
                @change="onEvent('surface', $event)"
              />
            </v-col>
            <v-col cols="12">
              <v-subheader class="title">
                Les atouts de votre bien
              </v-subheader>
              <v-switch
                :value="estimate.view"
                inset
                label="Vue exceptionnelle"
                @change="onEvent('view', $event)"
              />
              <v-switch
                :value="estimate.pool"
                inset
                label="Piscine"
                @change="onEvent('pool', $event)"
              />
            </v-col>

            <v-col cols="12">
              <v-subheader class="title">
                Extérieur
              </v-subheader>
              <v-radio-group
                :value="estimate.exterior"
                :rules="mandatory"
                @change="onEvent('exterior', $event)"
              >
                <v-radio
                  v-for="(option, id) in options.exterior"
                  :key="id"
                  :value="option.value"
                  :label="option.label"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-subheader class="title">
                Calme
              </v-subheader>
              <v-radio-group
                :value="estimate.quiet"
                :rules="mandatory"
                @change="onEvent('quiet', $event)"
              >
                <v-radio
                  v-for="(option, id) in options.quiet"
                  :key="id"
                  :value="option.value"
                  :label="option.label"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-subheader class="title">
                Ensoleillement
              </v-subheader>
              <v-radio-group
                :value="estimate.sunshine"
                :rules="mandatory"
                @change="onEvent('sunshine', $event)"
              >
                <v-radio
                  v-for="(option, id) in options.sunshine"
                  :key="id"
                  :value="option.value"
                  :label="option.label"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-subheader class="title">
                Vis-à-vis
              </v-subheader>
              <v-radio-group
                :value="estimate.opposite"
                :rules="mandatory"
                @change="onEvent('opposite', $event)"
              >
                <v-radio
                  v-for="(option, id) in options.opposite"
                  :key="id"
                  :value="option.value"
                  :label="option.label"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="headline">
          Contact
        </v-card-title>
        <v-card-subtitle>
          Un numéro de téléphone est nécessaire pour accéder à votre estimation.
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <vue-phone-number-input
                v-model="phone"
                label="Téléphone"
                name="phone"
                single-line
                :translations="translations"
                no-flags
                no-example
                color="#1976d2"
                valid-color="#4caf50"
                size="lg"
                @update="onPhoneEvent"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :value="estimate.firstName"
                label="Prénom"
                name="firstName"
                :rules="mandatory"
                single-line
                @input="onEvent('firstName', $event)"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :value="estimate.lastName"
                label="Nom"
                name="lastName"
                :rules="mandatory"
                single-line
                @input="onEvent('lastName', $event)"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                :value="estimate.email"
                label="Email"
                name="email"
                :rules="emailRules"
                single-line
                @input="onEvent('email', $event)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-btn
        color="primary"
        width="100%"
        x-large
        :disabled="!isValid"
        @click="onClick"
      >
        Estimez votre bien
      </v-btn>
    </v-form>
    <dialog-validation />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mdiMapMarker } from '@mdi/js'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import firebase from 'firebase/app'
import DialogValidation from './DialogValidation'
import 'firebase/auth'

export default {
  name: 'EstimateForm',
  components: {
    VuePhoneNumberInput,
    DialogValidation
  },
  data () {
    return {
      phone: '',
      validPhone: false,
      search: '',
      valid: false,
      mandatory: [
        (v) => !!v || v === 0 || 'Champ obligatoire'
      ],
      mandatoryObj: [
        v => !!(v) || 'Champ obligatoire'
      ],
      emailRules: [
        (v) => !!v || 'Champ obligatoire',
        (v) => /.+@.+\..+/.test(v) || 'Votre E-mail est invalide'
      ],
      icons: {
        mdiMapMarker
      },
      translations: {
        countrySelectorLabel: 'Code pays',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Numéro de téléphone',
        example: 'Exemple :'
      }
    }
  },
  computed: {
    ...mapState([
      'estimate',
      'options',
      'error',
      'selectedContent',
      'addressList',
      'isLoading'
    ]),
    ...mapGetters(['isReference']),
    isValid () {
      return this.valid && this.isReference && this.validPhone
    }
  },
  watch: {
    search: {
      handler (val) {
        if (val?.length < 5) return
        this.searchAddress(val)
      }
    }
  },
  mounted () {
    if (this.estimate.phone) {
      this.phone = this.estimate.phone
    }
  },
  methods: {
    ...mapActions([
      'setStateEstimate',
      'searchAddress',
      'setPriceReference',
      'setDialog'
    ]),
    onEvent (key, value) {
      this.setStateEstimate({ key, value })
    },
    onPhoneEvent (e) {
      this.validPhone = e.isValid
      this.setStateEstimate({ key: 'phone', value: e.formattedNumber })
    },
    onClick () {
      if (!firebase.auth().currentUser) {
        this.setDialog({ key: 'phoneValidation', value: true })
      } else {
        this.$router.push('/estimation')
        window.fbq('track', 'lead', {
          firstName: this.estimate.firstName,
          lastName: this.estimate.lastName,
          phone: this.estimate.phone
        })
      }
    }
  }
}
</script>
