<template>
  <v-dialog
    v-model="isOpen"
    max-width="600px"
    transition="dialog-bottom-transition"
    class="relative"
  >
    <v-card>
      <v-card-title class="text-h6">
        Entrer votre code d'activation
      </v-card-title>
      <v-card-text>
        <v-alert
          v-if="!!alert.message"
          :type="alert.type"
          dark
          width="100%"
        >
          {{ alert.message }}
        </v-alert>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        />
        <v-otp-input
          v-model="code"
          type="number"
          :disabled="loading"
          @finish="signInWithCredential"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="alert.subject === 'activation-code' && alert.type === 'error'"
          text
          color="primary"
          depressed
          @click="resetCaptcha"
        >
          Renvoyer un nouveau code
        </v-btn>
        <v-btn
          text
          @click="close"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'DialogValidation',
  data () {
    return {
      code: '',
      confirmationResult: {},
      alert: {
        type: '',
        message: '',
        subject: ''
      },
      loading: false,
      appVerifier: null
    }
  },
  computed: {
    ...mapState(['dialog', 'estimate']),
    isOpen: {
      get () {
        return this.dialog.phoneValidation
      },
      set (val) {
        this.setDialog({ key: 'phoneValidation', value: val })
      }
    }
  },
  watch: {
    isOpen (value) {
      if (!value) {
        this.clearAlert()
      } else {
        this.signInWithPhoneNumber()
      }
    }
  },
  mounted () {
    this.initRecaptcha()
  },
  methods: {
    ...mapActions(['setDialog']),
    initRecaptcha () {
      this.loading = true
      setTimeout(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',
          callback: () => {
            this.loading = false
          },
          'expired-callback': (response) => {
            this.loading = false
            this.alert = {
              type: 'error',
              message: 'Le code d\'activation n\'a pas pu être envoyé, cliquez sur le bouton pour en envoyer un nouveau',
              subject: 'activation-code'
            }
          }
        })
      }, 2000)
    },
    async resetCaptcha () {
      // reset the recaptchaVerifier
      await window.recaptchaVerifier.reset()
      this.initRecaptcha()
      await this.signInWithPhoneNumber()
    },
    async signInWithPhoneNumber () {
      this.clearAlert()
      this.loading = true
      const appVerifier = await window.recaptchaVerifier
      if (!appVerifier) this.loading = false

      return firebase.auth().signInWithPhoneNumber(this.estimate.phone, appVerifier)
        .then((confirmationResult) => {
          this.loading = false
          this.confirmationResult = confirmationResult
          this.alert = {
            type: 'success',
            message: 'Code d\'activation envoyé au ' + this.estimate.phone,
            subject: 'activation-code'
          }
          this.alert.message = 'Code d\'activation envoyé au ' + this.estimate.phone
        })
        .catch((error) => {
          this.loading = false
          console.log('signInWithPhoneNumber ' + error)
          this.alert = {
            type: 'error',
            message: 'Le code d\'activation n\'a pas pu être envoyé',
            subject: 'activation-code'
          }
        })
    },
    async signInWithCredential () {
      this.clearAlert()
      this.loading = true
      const credential = firebase.auth.PhoneAuthProvider.credential(this.confirmationResult.verificationId, this.code)
      try {
        await firebase.auth().signInWithCredential(credential)
        this.loading = false
        this.close()
        await this.$router.push({ name: 'estimation' })
      } catch (e) {
        this.loading = false
        this.alert = {
          type: 'error',
          message: 'Vous avez renseigné un mauvais code d\'activation',
          subject: 'activation-code'
        }
      }
    },
    close () {
      this.setDialog({ key: 'phoneValidation', value: false })
      this.clearAlert()
      this.loading = false
    },
    clearAlert () {
      this.alert = {
        type: '',
        message: '',
        subject: ''
      }
    }
  }
}
</script>
